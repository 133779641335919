import React from "react"
import Layout from "../components/layout"
import GetInvolved from "../page_components/home/get-involved"
import "../fonts/NunitoSansImport.css"
import "./press.css"
import nbcLogo1 from "../images/press/nbc-1.svg"
import nbcLogo2 from "../images/press/nbc-2.svg"
import linkedInLogo from "../images/press/linkedin.svg"
import nbcBayArea from "../images/press/nbcbayarea.svg"
import scripps from "../images/press/scripps.svg"
import candid from "../images/press/candid.svg"
import sfchronicle from "../images/press/sfchronicle.svg"
import nowThis from "../images/press/nowthis.svg"
import standtogether from "../images/press/standtogther.svg"
import usaToday from "../images/press/usatodaylogo.svg"
import trekMedics from "../images/press/trekmedicsinternational.svg"
import forbes from "../images/press/forbes.svg"
import ameriDisability from "../images/press/ameridisability.svg"
import abc from "../images/press/abc7news.svg"
import NewsCard from "../page_components/press/news-card"


export default function Press() {
  return (
    <Layout>
      <div className="pressTitle">
        <h1> <span>accesSOS</span> in the news </h1>
        <h2 style={{fontWeight:400}}> 
          Articles and press coverage highlighting our work and 
          mission of emergency access for all 
        </h2>
      </div>
      
      {/* <div className="titleBanner">
        <h1> Featured </h1>
        <div className="banner-red"></div>
      </div> */}
      <div className="labelWrapper">
        <div className="iframeContainer">
          <iframe loading='lazy' title="accesSOS on NBC National News with Lester Holt"
            src='https://www.nbcnews.com/news/embedded-video/mmvo194624069525' scrolling='no' frameborder='0' allowfullscreen></iframe>
        </div>
        <h4> accesSOS on NBC National News with Lester Holt </h4>
      </div>
      
      
      <div className="articles">
        <NewsCard
          logo={nbcLogo2}
          alt="NBC Logo"
          desc="Texting 911 can be the safer option sometimes – but your location may not be as accurate"
          link="https://www.nbcnews.com/nightly-news/video/texting-911-can-be-the-safer-option-sometimes-but-your-location-may-not-be-as-accurate-194624069525"
        />
        <NewsCard
          logo={usaToday}
          alt="USA Today Logo"
          desc="911 is a literal lifeline in our worst moments. Why does the system favor voice over text?"
          link="https://www.usatoday.com/story/opinion/voices/2023/11/07/maine-shooting-deaf-victims-text-911/71411416007/"
        />
        <NewsCard
          logo={nowThis}
          alt="Now This Logo"
          desc="The App Making 911 More Accessible for the Deaf Community (NowThis)"
          link="https://www.youtube.com/watch?v=XZjEDn0tjPw"
        />
        <NewsCard
          logo={forbes}
          alt="Forbes Logo"
          desc="California’s ‘Text-To-911’ System Inaccessible To Deaf Community"
          link="https://www.forbes.com/sites/stevenaquino/2023/10/09/californias-text-to-911-system-inaccessible-to-deaf-community-report-says/"
        />
        <NewsCard
          logo={nbcBayArea}
          alt="NBC Bay Area Logo"
          desc="Text-to-911 can’t geolocate as accurately as voice calls, puts vulnerable more at risk"
          link="https://www.nbcbayarea.com/investigations/text-to-911-geolocation-issues/3328725/"
        />
        <NewsCard
          logo={nbcLogo1}
          alt="GoodGoodGood Logo"
          desc="accesSOS's United Nations presentation has gone viral with more than 4M views"
          link="https://www.goodgoodgood.co/articles/accesssos-text-911"
        />
      </div>
      <div className="titleBanner second">
        <h1> More articles </h1>
        <div className="banner-red"></div>
      </div>
      <div className="articles">
        <NewsCard
          logo={standtogether}
          alt="Stand Together Logo"
          desc="7 quick facts about accesSOS - a text to 911 app that triages emergency responses and gets people the help they actually need "
          link="https://standtogether.org/news/accessos-is-making-emergency-help-accessible-by-text-with-a-text-to-911-app/"
        />
        <NewsCard
          logo={sfchronicle}
          alt="SF Chronicle Logo"
          desc="What will stop S.F. traffic deaths? Politicians push street changes, outreach to vulnerable"
          link="https://www.sfchronicle.com/bayarea/article/What-will-stop-S-F-traffic-deaths-Politicians-15718181.php"
        />
        <NewsCard
          logo={linkedInLogo}
          alt="LinkedIn Logo"
          desc="911 is a call away for most Americans, but for millions, they are left out of our emergency help system (The Female Quotient)"
          link="https://www.linkedin.com/posts/femalequotient_911-is-a-call-away-for-most-americans-but-activity-7064346770020950016-uIsc/?utm_source=share&utm_medium=member_desktop"
        />
        <NewsCard
          logo={forbes}
          alt="Forbes Logo"
          desc="She’s Making Emergency Help Accessible Through Text-to-911"
          link="https://www.forbes.com/sites/fastforward/2020/11/11/shes-making-emergency-help-accessible-through-text-to-911/?sh=1101e66ed1e8"
        />
        <NewsCard
          logo={abc}
          alt="abc7 Logo"
          desc="Social justice, equity-focused entrepreneurs pitch 'Tech for Good' ideas for cash"
          link="https://abc7news.com/tech-for-good-camelback-ventures-accessos-vocal-justice/11217453/"
        />
        <NewsCard
          logo={ameriDisability}
          alt="AmeriDisabilityLogo"
          desc="Emergency App Makes 911 Accessible for Deaf and Hard of Hearing"
          link="https://www.ameridisability.com/emergency-app-makes-911-accessible-for-deaf-and-hard-of-hearing/"
        />
        <NewsCard
          logo={candid}
          alt="Candid Logo"
          desc="accesSOS is recognized as a gold-level Guidestar participant"
          link="https://www.guidestar.org/profile/shared/aeb822ae-ee53-48f1-abf8-0da3f2d66d14"
        />
        <NewsCard
          logo={nbcBayArea}
          alt="NBC Bay Area Logo"
          desc="Woman voices concerns with 'Call If You Can, Text If You Can’t' 911 system"
          link="https://www.nbcbayarea.com/news/local/woman-voices-concerns-with-call-if-you-can-text-if-you-cant-911-system-2/3328422/"
        />
        <NewsCard
          logo={trekMedics}
          alt="Trek Medics International Logo"
          desc="Episode #6: Crisis Communications Technology for the Deaf and Hard of Hearing (The Crisis Response Podcast)"
          link="https://trekmedics.org/crisis-response-podcast/crisis-communications-technology-deaf-hard-of-hearing-accessos/"
        />
        <NewsCard
          logo={scripps}
          alt="Scripps News Logo"
          desc="Deaf Americans face danger with inadequate 911 text services"
          link="https://scrippsnews.com/stories/deaf-americans-face-danger-with-inadequate-911-text-services/"
        />
      </div>
      <GetInvolved/>
    </Layout>
  )
}
